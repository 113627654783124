import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Header from "../Header";
import styles from "./styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IParcel, IParcelRes } from "../Parcels/types";
import { getApi, putApiWithAuth } from "../../utils/apis";
import getAPIUrl from "../../config";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import Toast from "../../components/Toast";
import toastStyles from "../../components/Toast/styles";
import { object, string } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/InputField";
import { IVerifyCodeResponse } from "./types";
import { ParcelStatus } from "../AddParcel/types";

const IssueParcel = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [parcelDetails, setParcelDetails] = useState<IParcel>();
  const [qrCode, setQrCode] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/parcels");
  };

  const validationSchema = object({
    pickupCode: string().min(1, "Please add pick up code"),
  });

  const defaultValues: { pickupCode: string } = {
    pickupCode: "",
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data: { pickupCode: string }) => {
    try {
      setLoading(true);
      const verificationResponse: IVerifyCodeResponse = await getApi(
        `${getAPIUrl()}/api/v1/parcels/verify/qr-code?pickupCode=${data.pickupCode
        }`
      );
      if (verificationResponse.data.status === 200) {
        const res: any = await putApiWithAuth(
          `${getAPIUrl()}/api/v1/parcels/${id}`,
          {
            ...parcelDetails,

            description: '',
            parcelStatus: ParcelStatus.Issued,
          }
        );
        setSuccessToastMsg(res.data.message);
        setSuccessToast(true);
        reset(defaultValues);
        setTimeout(() => {
          setLoading(false);
          navigate("/parcels");
        }, 1000);
      }
    } catch (error) {
      setErrorToast(true);
      setErrorToastMsg("Invalid Pickup Code");
      setLoading(false);
    }
  };

  useEffect(() => {
    const getQrCode = async () => {
      try {
        const parcelDetailsData: IParcelRes = await getApi(
          `${getAPIUrl()}/api/v1/parcels/${id}`
        );

        setParcelDetails(parcelDetailsData.data);

        const qrCodeResponse = await getApi(
          `${getAPIUrl()}/api/v1/gr-code-generate?text=${parcelDetailsData.data?.deliveryCode
          }`
        );
        setQrCode(qrCodeResponse.data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
          setLoading(false);
        }
      }
    };

    getQrCode();
  }, [id]);
  return (
    <Grid sx={styles.container}>
      <Header
        name="Issue Parcel"
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.parcelDetailsContainer}>
        <Typography sx={styles.subHeading}>Parcel Information</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12} sx={styles.detailsContainer}>
                <Grid sx={styles.detail}>
                  <Typography sx={styles.detailHeaderText}>NAME</Typography>
                  <Typography
                    sx={styles.detailValueText}
                  >{`${parcelDetails?.user.firstName} ${parcelDetails?.user.lastName}`}</Typography>
                </Grid>

                <Grid sx={styles.detail}>
                  <Typography sx={styles.detailHeaderText}>
                    MOBILE NO.
                  </Typography>
                  <Typography sx={styles.detailValueText}>
                    {parcelDetails?.user.phone}
                  </Typography>
                </Grid>

                <Grid sx={styles.detail}>
                  <Typography sx={styles.detailHeaderText}>
                    LEFT FROM THE
                  </Typography>
                  <Typography sx={styles.detailValueText}>
                    {parcelDetails?.deliveryTime ?? "--"}
                  </Typography>
                </Grid>

                <Grid sx={styles.detail}>
                  <Typography sx={styles.detailHeaderText}>
                    DELIVERER
                  </Typography>
                  <Typography sx={styles.detailValueText}>
                    {parcelDetails?.deliveryCompany}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="pickupCode"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="pickupCode"
                      label="Pick up Code"
                      placeholder="Pick up Code"
                      type="text"
                      showCross
                      size="small"
                      fullWidth
                      handleCrossClick={() => setValue("pickupCode", "")}
                      error={!!errors.pickupCode?.message}
                      errorMessage={errors.pickupCode?.message}
                    />
                  )}
                />

                <div dangerouslySetInnerHTML={{ __html: qrCode }} />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="issue-parcel-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="issue-parcel-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default IssueParcel;
